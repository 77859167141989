import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import type { ButtonHTMLAttributes, ComponentProps, CSSProperties, ReactNode } from 'react';
import { Link } from 'react-router';
import { cn } from '~/utils/cn';
import { Icon, type IconName } from './ui/Icon';

const variants = cva( [
  'relative rounded-full overflow-hidden group/button cursor-pointer text-center',
  'before:absolute before:inset-0 before:bg-gradient-to-r before:bg-cover',
  'after:absolute after:inset-0 after:bg-gradient-to-r after:opacity-0 hover:after:opacity-100 after:transition-opacity after:duration-200 after:bg-cover',
  'disabled:pointer-events-none'
], {
  variants: {
    variant: {
      primary: [
        'text-white font-bold',
        'before:from-[#6739FF] before:to-[#6E52C3] before:rounded-full after:rounded-full',
        'after:from-[#6739FF] after:from-60% after:to-[#6E52C3] active:after:from-[#6739FF] active:after:to-[#6739FF]',
        'disabled:before:from-[#D5D3D3] disabled:before:to-[#9A9A9A]'
      ],
      secondary: [
        'font-bold',
        'before:from-[#0C102E] before:to-[#282C46] before:border-2 before:border-[#6739FF] before:rounded-full',
        'after:from-[#0C102E] after:to-[#282C46] after:border-2 after:border-[#6739FF] after:rounded-full active:after:to-[#131313] active:after:from-[#131313]',
        'disabled:before:border-[#656565] disabled:before:from-[#585656] disabled:before:to-[#585656]'
      ],
      shimmer: [
        'text-white font-bold',
        'before:from-[#6739FF] before:to-[#6E52C3] before:rounded-full after:rounded-full',
        'after:from-[#6739FF] after:from-60% after:to-[#6E52C3] active:after:from-[#6739FF] active:after:to-[#6739FF]',
        'disabled:before:from-[#D5D3D3] disabled:before:to-[#9A9A9A]'
      ],
      destructive: [
        'text-white font-bold',
        'before:from-[#FF3C3C] before:to-[#F00]',
        'after:from-[#FF3C3C] after:from-60% after:to-[#F00] active:after:from-[#FF3C3C] active:after:to-[#FF3C3C]',
        'disabled:before:from-[#D5D3D3] disabled:before:to-[#9A9A9A]'
      ]
    },
    size: {
      default: 'text-sm px-6 py-[13px]',
      sm: 'text-sm px-3.5 py-[8px]'
    }
  }
} );

export type ButtonProps = {
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  to?: string;
  /**
   * @deprecated Please use type property due conform with HTTML standards
   */
  submit?: boolean;
  iconName?: IconName;
  target?: string;
  isLoading?: boolean;
  reloadDocument?: boolean;
  disabled?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  name?: string;
  value?: string;
  prefetch?: ComponentProps<typeof Link>['prefetch'];
} & VariantProps<typeof variants>;

export const Button = ( { variant = 'primary', size = 'default', className, children, to, iconName, isLoading, prefetch, ...props }: ButtonProps ) => {
  const Comp = to ? Link : 'button';
  const buttonProps = {
    ...props
  };
  const linkProps = {
    prefetch,
    to,
    ...props
  }

  return (
    <Comp
      to={ to ?? '' }
      className={ cn( variants( { variant, size } ), className ) }
      { ...( to ? linkProps : buttonProps ) }
    >
      <span
        className={ clsx(
          'relative z-10 text-nowrap',
          {
            'group-hover/button:text-white': variant === 'secondary',
            'text-shimmer text-white/65 group-disabled/button:text-white': variant === 'shimmer',
            'flex flex-row items-center justify-center': iconName != null,
            'opacity-0': isLoading
          }
        ) }
        style={ {
          '--text-length': children?.toString().length,
          textShadow: '0px 2px 2px rgba(0,0,0,0.01)',
        } as CSSProperties }
      >
        { children }
        { iconName &&
          <Icon
            name={ iconName }
            className={ 'icon-size-5 ml-2' }
          />
        }
      </span>
      { isLoading &&
        <div className={ 'absolute inset-0 flex items-center justify-center' }>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className={ 'w-5 h-5' }
            xmlns="http://www.w3.org/2000/svg">
            <g className="spinner_V8m1">
              <circle
                cx="12"
                cy="12"
                r="9.5"
                fill="none"
                className={ 'stroke-[#6739FF]' }
                strokeWidth="3"
              ></circle>
            </g>
          </svg>
        </div>
      }
    </Comp>
  );
}
